export function waitForScrollEnd(element = window) {
  let last_changed_frame = 0;
  let last_x = element === window ? window.scrollY : element.scrollTop;
  let last_y = element === window ? window.scrollX : element.scrollLeft;
  return new Promise((resolve) => {
    function tick(frames) {
      const yProp = element === window ? window.scrollY : element.scrollTop;
      const xProp = element === window ? window.scrollX : element.scrollLeft;
      if (frames >= 500 || frames - last_changed_frame > 20) {
        resolve(null);
      } else {
        if (xProp != last_x || yProp != last_y) {
          last_changed_frame = frames;
          last_x = xProp;
          last_y = yProp;
        }
        requestAnimationFrame(tick.bind(null, frames + 1));
      }
    }
    tick(0);
  });
}
