var keys = {37: 1, 38: 1, 39: 1, 40: 1};
const preventDefaultCreator = (direction) => (e) => {
  if (!direction) {
    e.preventDefault();
    return;
  }
  const xMovement = Math.abs(e.touches[0].clientX - e.target._startTouch.x);
  const yMovement = Math.abs(e.touches[0].clientY - e.target._startTouch.y);
  if (direction === "y" && xMovement < yMovement * 3 || direction === "x" && yMovement < xMovement * 3) {
    if (e.cancelable)
      e.preventDefault();
  }
};
const preventDefaultX = preventDefaultCreator("x");
const preventDefaultY = preventDefaultCreator("y");
const preventDefault = preventDefaultCreator(null);
function addDirectionHandler(e) {
  e.target._startTouch = {
    x: e.touches[0].clientX,
    y: e.touches[0].clientY
  };
}
function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, "passive", {
    get: function() {
      supportsPassive = true;
    }
  }));
} catch (e) {
}
var wheelOpt = supportsPassive ? {passive: false} : false;
var wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";
export function disableScroll(element, direction) {
  element.addEventListener("DOMMouseScroll", preventDefault, false);
  element.addEventListener(wheelEvent, preventDefault, wheelOpt);
  element.addEventListener("touchmove", direction === "x" ? preventDefaultX : preventDefaultY, wheelOpt);
  element.addEventListener("touchstart", addDirectionHandler, wheelOpt);
  element.addEventListener("keydown", preventDefaultForScrollKeys, false);
}
export function enableScroll(element, direction) {
  element.removeEventListener("DOMMouseScroll", preventDefault, false);
  element.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  element.removeEventListener("touchmove", direction === "x" ? preventDefaultX : preventDefaultY, wheelOpt);
  element.removeEventListener("touchstart", addDirectionHandler, wheelOpt);
  element.removeEventListener("keydown", preventDefaultForScrollKeys, false);
}
