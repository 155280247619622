import {html, render} from "https://cdn.skypack.dev/uhtml@3.2.2/async";
import {searchForm} from "./templates/searchForm.js";
import {throttle} from "./helpers/throttle.js";
import {getIds} from "./helpers/getIds.js";
import {columnsRender} from "./templates/columnsRender.js";
import "./misc/bodyClick.js";
import {continueColumnsRender} from "./helpers/continueColumnsRender.js";
import {debounce} from "./helpers/debounce.js";
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("./sw.js", {scope: "/"}).then((reg) => {
    console.log("Registration succeeded. Scope is " + reg.scope);
  }).catch((error) => {
    console.log("Registration failed with " + error);
  });
}
const debouncedContinueColumnsRender = debounce(continueColumnsRender, 700);
let previousRoute = "";
const routes = {
  columns: () => {
    const ids = getIds();
    return columnsRender(ids);
  },
  search: () => searchForm()
};
export const drawApp = throttle(async () => {
  const ids = getIds();
  const routeName = ids.length ? "columns" : "search";
  try {
    await render(document.body, routes[routeName]());
    if (previousRoute !== routeName)
      setTimeout(() => {
        continueColumnsRender(true);
      }, 700);
    previousRoute = routeName;
  } catch (exception) {
    if (exception.message === "NetworkError when attempting to fetch resource.") {
      render(document.body, html`<h1 class="dbpedia-offline">Unfortunatly DBpedia is down.<br>Please come back later.</h1>`);
    } else {
      console.info(exception);
    }
  }
}, 300);
drawApp().then(debouncedContinueColumnsRender);
window.addEventListener("popstate", drawApp);
